module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"omitGoogleFont":true,"pathToConfigModule":"config/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/jimmy/www/beldone/editor-groupe/desastre-gatsby-v4/node_modules/gatsby-remark-external-links","id":"593360cc-8005-54c1-83fc-832d5930651f","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"/Users/jimmy/www/beldone/editor-groupe/desastre-gatsby-v4/node_modules/gatsby-remark-external-links/index.js","pluginOptions":{"plugins":[],"rel":"noreferrer noopener","target":"_blank"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/jimmy/www/beldone/editor-groupe/desastre-gatsby-v4","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ffffff","description":"Filiale du Groupe Editor","display":"minimal-ui","icon":"src/images/icons/icon.png","icons":[{"sizes":"48x48","src":"/favicons/icon-48x48.png","type":"image/png"},{"sizes":"72x72","src":"/favicons/icon-72x72.png","type":"image/png"},{"sizes":"96x96","src":"/favicons/icon-96x96.png","type":"image/png"},{"sizes":"144x144","src":"/favicons/icon-144x144.png","type":"image/png"},{"sizes":"192x192","src":"/favicons/icon-192x192.png","type":"image/png"},{"sizes":"256x256","src":"/favicons/icon-256x256.png","type":"image/png"},{"sizes":"384x384","src":"/favicons/icon-384x384.png","type":"image/png"},{"sizes":"512x512","src":"/favicons/icon-512x512.png","type":"image/png"}],"lang":"fr","localize":[{"description":"a subsidiary of Groupe Editor","lang":"en","name":"Les Éditions du Désastre","short_name":"Les Éditions du Désastre","start_url":"/en/"}],"name":"Les Éditions du Désastre","short_name":"Les Éditions du Désastre","start_url":"/","theme_color":"#2E2F5C","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"39e9a6f98707183b195bf707fce2b505"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"fields":[{"attributes":{"boost":20},"name":"title","store":true},{"attributes":{"boost":10},"name":"description","store":true},{"attributes":{"boost":10},"name":"content","store":true},{"name":"url","store":true},{"attributes":{"boost":5},"name":"tags","store":true}],"filename":"search_index.json","languages":[{"name":"fr"},{"name":"en"}],"resolvers":{"Mdx":{},"Products":{}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
