exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-group-jsx": () => import("./../../../src/pages/group.jsx" /* webpackChunkName: "component---src-pages-group-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-notices-jsx": () => import("./../../../src/pages/legal-notices.jsx" /* webpackChunkName: "component---src-pages-legal-notices-jsx" */),
  "component---src-pages-partnership-jsx": () => import("./../../../src/pages/partnership.jsx" /* webpackChunkName: "component---src-pages-partnership-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-views-templates-artist-item-jsx": () => import("./../../../src/views/templates/ArtistItem.jsx" /* webpackChunkName: "component---src-views-templates-artist-item-jsx" */),
  "component---src-views-templates-product-gallery-jsx": () => import("./../../../src/views/templates/ProductGallery.jsx" /* webpackChunkName: "component---src-views-templates-product-gallery-jsx" */),
  "component---src-views-templates-product-item-jsx": () => import("./../../../src/views/templates/ProductItem.jsx" /* webpackChunkName: "component---src-views-templates-product-item-jsx" */)
}

