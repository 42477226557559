/* eslint-disable react/jsx-filename-extension */
import 'typeface-montserrat';
import 'typeface-raleway';
import 'typeface-roboto';

import './src/styles/vendors/sanitize.styl';
import './src/styles/vendors/reset.styl';
import './src/styles/global.styl';
import './src/fonts/style.styl';

import React from 'react';

import CaptchaProvider from './src/providers/CaptchaProvider';

export const onServiceWorkerUpdateReady = () => window.location.reload(true);

export const onClientEntry = async () => {
    // Without this function body the import will not be picked up.

    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
        console.log(`# IntersectionObserver is polyfilled!`);
    }
};

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element, props }) => (
    <CaptchaProvider
        // eslint-disable-next-line react/prop-types
        locale={props.pageContext.locale}
    >
        {element}
    </CaptchaProvider>
);
