import React from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useStaticQuery, graphql } from 'gatsby';

const CaptchaProvider = ({ children, locale }) => {
    const data = useStaticQuery(graphql`
        query Captcha {
            site {
                siteMetadata {
                    reCaptchaKey
                }
            }
        }
    `);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={data.site.siteMetadata.reCaptchaKey}
            language={locale}
        >
            {children}
        </GoogleReCaptchaProvider>
    );
};

CaptchaProvider.propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string.isRequired,
};

export default CaptchaProvider;
